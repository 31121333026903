import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@core/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignatoryService {
  http = inject(HttpClient);
  BASE_URL = environment.ADMIN_BASE_URL + '/v1/account';

  removeUserFromAccount(userId: string, accountId: string) {
    return this.http.post(`${this.BASE_URL}/remove-user-from-account`, {
      profile_id: userId,
      account_id: accountId,
    });
  }

  deactivateSignatory(signatoryId: string, accountId: string) {
    return this.http.post(`${this.BASE_URL}/deactivate-account-signatory`, {
      profile_id: signatoryId,
      account_id: accountId,
    });
  }
}
